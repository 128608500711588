export const postPurchaseRequiredElements = [
  'PostPurchaseProductOffer',
  'PostPurchaseProductTitle',
  'PostPurchaseProductPrice',
  'PostPurchaseAcceptButton',
  'PostPurchaseButton',
  'PostPurchaseCalloutBox',
];

export const postPurchaseWrapElements = ['Product', 'Row', 'Section'];
