import useEditorStore from '../../common/stores/editor';

export const useSaleFunnel = () => {
  const editorStore = useEditorStore();
  const editingPageType = computed(() => {
    return editorStore.getEditingPageType ?? '';
  });

  const isPostPurchasePage = computed(() => {
    return editingPageType.value === 'POST_PURCHASE';
  });

  const isSalePage = computed(() => {
    return editingPageType.value === 'GP_FUNNEL_PAGE';
  });

  const isPreSalesPage = computed(() => {
    return editingPageType.value === 'GP_PRE_SALE_PAGE';
  });

  const isSalesFunnelPage = computed(() => {
    return isPostPurchasePage.value || isSalePage.value || isPreSalesPage.value;
  });

  return {
    isPostPurchasePage,
    isSalePage,
    isSalesFunnelPage,
    editingPageType,
    isPreSalesPage,
  };
};
